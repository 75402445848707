import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import invert from 'invert-color'
import Flip from 'react-reveal/Flip'

import Layout from '../components/Layout'

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700');
  
  font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	padding-top: 1rem;
	justify-content: center;
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 90%;
	background-color: ${props => props.backgroundColor};
	padding: 1rem;
	border-radius: 5px;
  color: ${props => props.color};
`

const Header = styled.h1`
  margin: 2rem 0;
`

const Subheader = styled.h2`
  margin: 2rem 0;
  font-size: 2rem;
`

const Text = styled.p`
  margin: 2rem 0;
`

const Button = styled.a.attrs({
  target: '_blank'
})`
  color: ${props => props.color};
  margin: 2rem 0 1rem 0;
  border-radius: 5px;
  border: solid 3px ${props => props.borderColor};
	background-color: transparent;
  font-size: 1.5rem;
  padding: 5px 20px;
  text-transform: uppercase;
  text-decoration: none;
`

const Box = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  min-height: 3rem;
  border-radius: 5px;
  padding: 0 1rem;
`

const QuickOffer = ({ data: { markdownRemark: { frontmatter } } }) => {
  const textPrimaryColor = frontmatter.textColor || invert(frontmatter.primaryColor, true)
  const textSecondaryColor = frontmatter.textColor || invert(frontmatter.secondaryColor, true)

  return (
    <Layout frontmatter={frontmatter}>
      <Flip left ssrReveal>
        <Wrapper>
          <Card color={textPrimaryColor} backgroundColor={frontmatter.primaryColor}>

            <Box backgroundColor={frontmatter.secondaryColor} />
            <Header>{frontmatter.header}</Header>

            <Box backgroundColor={frontmatter.secondaryColor} />
            <Subheader>{frontmatter.subheader}</Subheader>

            <Box color={textSecondaryColor} backgroundColor={frontmatter.secondaryColor}>
              <Text>{frontmatter.text}</Text>
            </Box>

            <Button color={textPrimaryColor} borderColor={textPrimaryColor} href={frontmatter.link}>{frontmatter.buttonText}</Button>

          </Card>
        </Wrapper>
      </Flip>
    </Layout>
  )
}

export default QuickOffer

export const pageQuery = graphql`
  query QuickOfferBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        buttonText
        link
        primaryColor
        secondaryColor
        textColor
        favicon {
          publicURL
        }
      }
    }
  }
`
